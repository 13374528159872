import React from 'react'
import Nav from './Nav'
import './header.scss'

const Header = props => (
  <header className="header">
    <Nav {...props} />
  </header>
)

export default Header
