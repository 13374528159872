import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import MenuButton from './MenuButton'

import './nav.scss'

import brandLogo from '../images/brand-logo.svg'

const Nav = ({ menuButtonClickHandler }) => (
  <nav className="nav container">
    <Link className="nav__brand" to="/">
      <img className="nav__brand-img" src={brandLogo} alt="Nightlight Logo" />
    </Link>
    <MenuButton click={menuButtonClickHandler} />
    <ul className="nav__menu">
      <li className="nav__menu-item">
        <a href="https://itunes.apple.com/app/id1474711114" 
          className="nav__menu-link" 
          target="_blank"
          rel="noopener noreferrer">
          Get Nightlight
        </a>
      </li>
      <li className="nav__menu-item">
        <Link to="/about" className="nav__menu-link">
          About
        </Link>
      </li>
      <li className="nav__menu-item">
        <Link to="/faq" className="nav__menu-link">
          FAQ
        </Link>
      </li>
    </ul>
  </nav>
)

Nav.propTypes = {
  menuButtonClickHandler: PropTypes.func.isRequired,
}

export default Nav
