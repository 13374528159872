import PropTypes from 'prop-types'
import React from 'react'

import './menu-button.scss'

const MenuButton = ({ click }) => (
  <div className="menu-button" onClick={click}>
    <span className="menu-button__line"></span>
    <span className="menu-button__line"></span>
    <span className="menu-button__line"></span>
  </div>
)

MenuButton.propType = {
  click: PropTypes.func.isRequired,
}

export default MenuButton
