import { Link } from 'gatsby'
import React from 'react'

import './mobile-nav.scss'

const MobileNav = ({ show, didSelectNavItem }) => {
  const itemClass = `nav__menu-item${show ? ' fade' : ''}`

  return (
    <nav className={`nav mobile-nav${show ? ' open' : ''}`}>
      <ul className="nav__menu container">
        <li onClick={didSelectNavItem} className={itemClass}>
          <a
            href="https://itunes.apple.com/app/id1474711114"
            className="nav__menu-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Nightlight
          </a>
        </li>
        <li onClick={didSelectNavItem} className={itemClass}>
          <Link to="/about" className="nav__menu-link">
            About
          </Link>
        </li>
        <li onClick={didSelectNavItem} className={itemClass}>
          <Link to="/faq" className="nav__menu-link">
            FAQ
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default MobileNav
