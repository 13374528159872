import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import Footer from './Footer'
import MobileNav from './MobileNav'
import './layout.scss'

const Layout = ({ children, background }) => {
  const [isMobileMenuOpen, setMenuState] = useState(false)

  const toggleMenu = () => {
    setMenuState(!isMobileMenuOpen)
  }

  return (
    <div className="layout">
      <Header menuButtonClickHandler={toggleMenu} />
      <MobileNav show={isMobileMenuOpen} didSelectNavItem={toggleMenu} />
      <div className="background" style={{ background }} />
      <main className="container">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defautProps = {
  background: '#ffffff',
}

export default Layout
