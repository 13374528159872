import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import appIcon from '../images/app-icon.png'
import world from '../images/world.svg'
import message from '../images/message.svg'
import appreciation from '../images/appreciation.svg'
import appStore from '../images/app-store.svg'
import './index.scss'

import Image from '../components/image'

const IndexPage = () => (
  <Layout background="#31363A">
    <SEO title="Landing Page" />
    <div className="section-container">
      <div className="left-section">
        <section className="page__section section__header">
          <img
            className="section__header-img"
            src={appIcon}
            alt="Nightlight App Icon"
          />
          <div className="section__header-title">
            <h1>Nightlight</h1>
            <h4>Start helping people today.</h4>
          </div>
        </section>
        <section className="page__section section__info">
          <div className="section__info-block">
            <img className="section__info-block-img" src={world} alt="Earth" />
            <div className="section__info-block-content">
              <h4 className="section__info-block-content-title">
                Spread positivity and kindness.
              </h4>
              <p className="section__info-block-content-body">
                The world could use more of it. We all need help sometimes
                whether it be words of kindness or just knowing that someone is
                there.
              </p>
            </div>
          </div>
          <div className="section__info-block">
            <img
              className="section__info-block-img"
              src={message}
              alt="Message"
            />
            <div className="section__info-block-content">
              <h4 className="section__info-block-content-title">
                Make a difference with your words.
              </h4>
              <p className="section__info-block-content-body">
                Send helpful, kind or motivational messages to brighten
                someone's day. Send messages anonymously if you don't want to
                draw too much attention to yourself.
              </p>
            </div>
          </div>
          <div className="section__info-block">
            <img
              className="section__info-block-img"
              src={appreciation}
              alt="Appreciation"
            />
            <div className="section__info-block-content">
              <h4 className="section__info-block-content-title">
                Show love and appreciation.
              </h4>
              <p className="section__info-block-content-body">
                Celebrate people who are trying to make a difference. Receiving
                love and appreciation shows the impact of your words.
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className="right-section">
        <section className="section__demo">
          <Image />
        </section>
      </div>
    </div>
    <section className="section__action">
      <a
        href="https://itunes.apple.com/app/id1474711114"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="img__app-store"
          src={appStore}
          alt="Available on the App Store"
        />
      </a>
    </section>
  </Layout>
)

export default IndexPage
